$color--green: #11efa1; // eGreen in Rainbow
$color--red: #e23b3e; // scarlet in Rainbow
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  box-shadow: inset 0px 0px 0px $color--green;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;

  &.error {
    box-shadow: inset 0px 0px 0px $color--red;
    animation: fill-error 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $color--green;
  fill: none;
  animation: stroke 0.6s $curve forwards;

  &.error {
    stroke: $color--red;
  }
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px;

  animation: stroke 0.3s $curve 0.8s forwards;
}

.backslash {
  animation-delay: 1s;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 35px $color--green;
  }
}

@keyframes fill-error {
  100% {
    box-shadow: inset 0px 0px 0px 35px $color--red;
  }
}
